<script setup lang="ts">
import { getHtmlFromRichText } from '@mop/cms/utils/utils';

defineOptions({
  name: 'MopCmsGuestBuyerPlpPromotionItem',
});

const { cmsGlobalStoryListModelRef } = useMopCmsGlobalComponents();
const guestBuyerPlpStoryRef = ref(
  cmsGlobalStoryListModelRef.value.getStoryModelByName(constants.STORYBLOK.GLOBAL_STORY_NAMES.GUEST_BUYER_PAGE)!,
);
const GRID_ROWS_NUMBER = 4;

const randomPromoItemRef = computed(() => {
  let promoItems = guestBuyerPlpStoryRef.value.getAttribute('plpPromoItems');

  if (!promoItems) {
    return {};
  }

  promoItems = promoItems.map((item: any) => ({
    headline: item.headline,
    text: getHtmlFromRichText(item.text),
    isTooltipVisible: item.isTooltipVisible,
    tooltipText: getHtmlFromRichText(item.tooltipText),
    ctaText: item.ctaText,
  }));

  const randomNumber = Math.floor(Math.random() * promoItems.length);

  return promoItems[randomNumber];
});

const itemGridColumnPosition = Math.floor(Math.random() * GRID_ROWS_NUMBER) + 1;
</script>

<template>
  <div class="guest-buyer-plp-promotion-item" :style="`grid-column:${itemGridColumnPosition}`" data-theme="dark">
    <div class="guest-buyer-plp-promotion-item__content">
      <div v-if="randomPromoItemRef.headline" class="guest-buyer-plp-promotion-item__headline">
        {{ randomPromoItemRef.headline }}
      </div>
      <div class="guest-buyer-plp-promotion-item__text">
        {{ randomPromoItemRef.text }}
        <span class="guest-buyer-plp-promotion-item__tooltip">
          <Ui2Tooltip
            v-if="randomPromoItemRef.isTooltipVisible"
            data-theme="default"
            placement="top-end"
            trigger="mouseover"
            no-cursor
            allow-click
          >
            <template #contentSlot>
              <div class="guest-buyer-plp-promotion-item__tooltip-content" v-html="randomPromoItemRef.tooltipText" />
            </template>
            <Ui2Icon name="info-circle" :width="20" :height="20" />
          </Ui2Tooltip>
        </span>
      </div>
      <Ui2Button
        v-if="randomPromoItemRef.ctaText"
        :label="randomPromoItemRef.ctaText"
        appearance="primary"
        size="sm"
        html-type="link"
        class="guest-buyer-plp-promotion-item__button"
        :to="$mopI18n.localePath($urls.AUTH_REGISTER)"
      />
    </div>
    <img
      src="@mop/shared/images/misc/members-logo-white.svg?url"
      class="guest-buyer-plp-promotion-item__logo"
      width="50"
      height="65"
      alt="mop logo"
      loading="lazy"
    />
  </div>
</template>

<style scoped lang="scss">
.guest-buyer-plp-promotion-item {
  grid-row: 5;
  display: flex;
  width: 100%;
  height: 100%;
  padding: $space-24;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: $color-surface-primary;
  color: $color-text-body-secondary;

  @include apply-upto(large) {
    padding: $space-16;
  }
}

.guest-buyer-plp-promotion-item__tooltip {
  display: inline-block;
  position: absolute;
  padding-left: 3px;
}

.guest-buyer-plp-promotion-item__tooltip-content {
  @include v2-text-style(xs, regular);
  min-width: 300px;
}

.guest-buyer-plp-promotion-item__headline {
  @include v2-text-style(lg, highlight);

  @include apply-upto(large) {
    @include v2-text-style(md, highlight);
  }
}

.guest-buyer-plp-promotion-item__text {
  margin: $space-12 0 $space-24;
  @include v2-text-style(md);

  @include apply-upto(large) {
    @include v2-text-style(sm);
  }
}

.guest-buyer-plp-promotion-item__button {
  @include v2-text-style(sm, highlight);

  background-color: $color-surface-action-1;
  color: $color-text-on-action;

  @include apply-upto(large) {
    @include v2-text-style(xs, highlight);
  }
}

.guest-buyer-plp-promotion-item__logo {
  display: block;
}
</style>
